/* globals utils debug IdbStore events _ */

// Interface for persistent storage of typed array audio chunks
// in indexeddb

var global = this;

(function () {
  'use strict'

  var dbg = debug('zc:persistentStore')

  var PersistentStore = (function () {
    var PersistentStore = function (config) {
      config = config || {}
      dbg.enabled = config.debug
      dbg('Initialized with config: ', config)

      this.name = config.name || this.constructor.name
      this.format = config.format
      this.parentId = config.parentId
      this.dbName = this.format + '::' + this.parentId
      this.storeName = this.dbName
      this.bytesSaved = 0
      this.saveError = false

      // create IdbStore but don't connect yet
      // connections happen as needed and are managed by IdbStore
      this.db = new IdbStore({
        dbName: this.dbName,
        version: 2,
        stores: [
          {name: this.storeName, options: {autoIncrement: true}, indexes: []},
          {name: 'meta', options: {autoIncrement: true}, indexes: []}
        ],
        storeOptions: { autoIncrement: true },
        metaDefaults: {
          totalBytes: 0,
          duration: 0
        }
      })
    }

    PersistentStore.prototype._merge = function (chunks) {
      return new Blob(chunks)
    }

    PersistentStore.prototype._metaCreator = function (item, oldMeta) {
      var newMeta = {
        totalBytes: oldMeta.totalBytes += item.byteLength,
        start: oldMeta.start || Date.now(),
        duration: Date.now() - oldMeta.start
      }

      return newMeta
    }

    // get database info from meta table
    PersistentStore.prototype.getDbMeta = function () {
      var self = this
      var wasAlreadyConnected = this.db.connectionOpen
      return this.db.getDbMeta().then(function (dbMeta) {
        // Often we get the db meta just check it's size
        // without any further need for the db connection.
        // If we weren't already using the db, close it backup after the query
        if (!wasAlreadyConnected && self.db.connectionOpen) {
          return self.dbClose().then(function () {
            return dbMeta
          })
        } else {
          return dbMeta
        }
      })
    }

    PersistentStore.prototype.updateDbMeta = function (item) {
      var self = this
      return self.db.updateDbMeta(item, self._metaCreator).then(function (newDbMeta) {
        return newDbMeta
      })
    }

    PersistentStore.prototype.add = function (item) {
      return this.db.add(item)
    }

    PersistentStore.prototype.addAudio = function (audioChunk) {
      var self = this
      if (dbg.enabled) this.saveStartTime = performance.now()

      var item = {
        byteLength: audioChunk.size,
        audio: audioChunk,
        timestamp: performance.now()
      }

      dbg('addAudio attrs: ', item)

      // add audio with meta columns
      return this.db.add(item).then(function () {
        dbg('addAudio saved: ', item)
        return self.updateDbMeta(item).then(function (dbMeta) {
          dbg('addAudio dbMeta: ', dbMeta)
          if (self.bytesSaved !== dbMeta.totalBytes) {
            self.trigger('change:bytesSaved', self.bytesSaved = dbMeta.totalBytes)
          }
          return dbMeta.totalBytes
        })
      })
    }

    PersistentStore.prototype.getAllCursors = function (unmerged) {
      return this.db.getAllUsingCursor()
    }

    PersistentStore.prototype.mergedExport = function () {
      return this.getAllCursors().then(function (items) {
        if (!items.length) return [] // empty
        return this._merge(items)
      })
    }

    PersistentStore.prototype.dbClose = function () {
      return this.db.close()
    }

    PersistentStore.prototype.dbDelete = function () {
      return this.db.deleteDatabase()
    }

    PersistentStore.prototype.calcStorageUsed = function () {
      return this.getDbMeta().then(function (meta) {
        return (meta && meta.totalBytes) || 0
      })
    }
    /**
     *
     * @param raw {boolean} Used to indicate if the 'audio' property should be plucked or not
     * @returns {Promise<*>}
     */
    PersistentStore.prototype.extractData = async function (raw) {
      var data = await this.getAllCursors()
      if (raw) {
        return data
      } else {
        return _.pluck(data, 'audio')
      }
    }

    PersistentStore.prototype.export = async function () {
      var audioChunks = await this.extractData()

      if (!audioChunks.length) throw new Error('Store is empty')

      return utils.audio.convertAudioChunksToFormattedBlob(this.format, audioChunks)
    }

    PersistentStore.prototype.handleSaveError = function (err) {
      // only show the save error once
      if (!this.saveError) {
        this.saveError = err
        // err is just a string here
        if (err === 'QuotaExceededError') {
          err = 'QuotaExceededError: You have run out of free disk space to save a local backup of this data.' +
          'I recommend that you stop the recording and clear out some disk space to prevent possible data loss. ' +
          'Please see the <a target="_blank" href="https://support.zencastr.com/system-requirements/what-are-the-system-requirements-for-zencastr">system requirements</a> for more information.'
          window.utils && window.utils.notify && window.utils.notify('error', err)
        } else {
          utils.notify('error', 'Problem saving local backup of audio. ', err)
        }
      }
    }

    events.mixin(PersistentStore.prototype)

    return PersistentStore
  })()

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = PersistentStore
  else global.PersistentStore = PersistentStore
})()

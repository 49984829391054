/* globals debug workerPromise CloudStore events */

var global = this;

// This is an interface that lives on the main thread but proxies
// it's function calls to an instance of CloudStoreStream running
// inside of a web worker based audio pipeline

(function () {
  'use strict'

  var dbg = debug('zc:cloudStoreWorkerProxy')

  var CloudStoreWorkerProxy = (function () {
    var CloudStoreWorkerProxy = function (config) {
      dbg('Initialized with config: ', config)
      CloudStore.call(this, config)

      if (config.workerPort && !(config.workerPort instanceof MessagePort)) {
        throw new Error('config.workerPort must an instance of MessagePort')
      }

      // if we don't get a messagePort on initialization,
      // then create one.
      // This is we communicate with the worker based CloudStore
      if (config.workerPort) {
        this.workerPort = config.workerPort
      } else {
        this.messageChannel = new MessageChannel()
        this.workerPort = this.messageChannel.port1
      }

      this.initPortListeners()
    }

    // must be first after constructor
    CloudStoreWorkerProxy.prototype = Object.create(CloudStore.prototype)

    CloudStoreWorkerProxy.prototype.initPortListeners = function () {
      var self = this
      var port = this.workerPort
      port.addEventListener('message', function (e) {
        switch (e.data.command) {
          case 'ready':
            self.trigger('ready')
            break
          case 'chunkUploaded':
            self.trigger('chunkUploaded', e.data.chunkMeta)
            break
          case 'change:bytesUploaded':
            self.trigger('change:bytesUploaded', e.data.bytesUploaded)
            break
          case 'recreateUploadUrl':
            self.trigger('recreateUploadUrl')
            break
          case 'abort':
            self.trigger('abort')
            break
        }
      }, false)

      port.start()
    }

    CloudStoreWorkerProxy.prototype.getMessagePort = function () {
      return this.messageChannel.port2
    }

    CloudStoreWorkerProxy.prototype.createUploadUrl = function (projectId, path) {
      var self = this
      return this.workerPostMessage({
        command: 'createUploadUrl',
        projectId: projectId,
        path: path
      }).then(function (url) {
        self.uploadUrl = url
        return url
      })
    }

    CloudStoreWorkerProxy.prototype.initStreamingUpload = function (url) {
      return this.workerPostMessage({
        command: 'initStreamingUpload',
        url: url
      })
    }

    CloudStoreWorkerProxy.prototype.upload = function (url, blob) {
      return this.workerPostMessage({
        command: 'upload',
        url: url,
        blob: blob
      })
    }

    CloudStoreWorkerProxy.prototype.createDownloadUrl = function (projectId, path) {
      var self = this
      return this.workerPostMessage({
        command: 'createDownloadUrl',
        projectId: projectId,
        path: path
      }).then(function (url) {
        self.downloadUrl = url
        return url
      })
    }

    CloudStoreWorkerProxy.prototype.getResumableUploadProgress = function () {
      return this.workerPostMessage({command: 'getResumableUploadProgress'})
    }

    workerPromise.mixin(CloudStoreWorkerProxy.prototype)
    events.mixin(CloudStoreWorkerProxy.prototype)

    return CloudStoreWorkerProxy
  })()

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = CloudStoreWorkerProxy
  else global.CloudStoreWorkerProxy = CloudStoreWorkerProxy
})()


var global = this;

(function () {
  'use strict'

  var Logger = (function () {
    var logDnaUrl = ''

    var Logger = {}
    Logger.initialize = function (options) {
      // 1. if this is called again (should not happen), servars.logging is undefiend
      // 2. we only enable this module in production
      // 3. make sure we have the url
      if (!options || options.browserLogging === 'disabled' || !options.url) return

      /**
       * This is were we'll save the log lines to batch send them to logdna
       * @type {Array}
       */
      this._logLines = []
      this._logTimeout = null

      /**
       * How many logs we should collect
       * before sending them to logdna
       * @type {Number}
       */
      this.batchSize = 300

      /**
       * How many ms we should wait for the queue to fill up
       * If it doesn't just send the logs that we have until then
       * @type {Number}
       */
      this.queueTimeout = 10000

      /**
       * The default app name in logdna for these logs
       * @type {String}
       */
      this.defaultApp = 'frontend'
      this.defaultLogLevel = 'debug'

      /**
       * The logs from debug go to a different app
       * @type {String}
       */
      this.debugAppName = 'debug-frontend'

      /**
       * Just a flag to keep track if we should buffer debug or send them to logdna
       * @type {Boolean}
       */
      this._enabled = false

      /**
       * If we've received an additional metadata object, use it
       * These will be added to every log
       * @type {Object}
       */
      this.metaData = options.metaData || {}

      // save the url internally and delete it from global
      logDnaUrl = options.url
      // delete global.servars.logging

      // if we want to only send console log, or everything
      if (['console', 'all'].indexOf(options.browserLogging) !== -1) {
        // initiliaze the listeners to console.log, etc
        this.initLogListeners()
      }

      // if we want to only send debug calls, or everything
      if (['debug', 'all'].indexOf(options.browserLogging) !== -1) {
        // replace the global debug function with our own
        this.replaceDebug()
      }
    }

    /**
     * Used to wrap the main console methods and collect logs
     * @return {[type]} [description]
     */
    Logger.initLogListeners = function () {
      var self = this

      // wrap native methods

      // console.log
      var origConsoleLog = console.log.bind(console.log)
      console.log = function () {
        try {
          self.saveLog({
            logLine: self.createLogLine(arguments),
            level: 'debug'
          })
        } finally {
          origConsoleLog.apply(console.log, arguments)
        }
      }

      // console.warn
      var origConsoleWarn = console.warn.bind(console.warn)
      console.warn = function () {
        try {
          self.saveLog({
            logLine: self.createLogLine(arguments),
            level: 'warn'
          })
        } finally {
          origConsoleWarn.apply(console.warn, arguments)
        }
      }

      // console.error
      var origConsoleError = console.error.bind(console.error)
      console.error = function () {
        try {
          self.saveLog({
            logLine: self.createLogLine(arguments),
            level: 'err'
          })
        } finally {
          origConsoleError.apply(console.error, arguments)
        }
      }
    }

    /**
     * Used to get a log level depending on the namespace
     * @param  {String} namespace The debug namespace
     * @return {String}           The log level: info, debug (default), error
     */
    Logger.getLogLevel = function (namespace, logLine) {
      // if we have the string Error: in the line, it's most likely a js error
      // the log level should be error in this case
      if (logLine && logLine.indexOf('Error: ') > -1) {
        return 'error'
      }

      return ['zc:recorder', 'zc:project'].includes(namespace) ? 'info' : 'debug'
    }

    /**
     * Used to replace the global debug function
     */
    Logger.replaceDebug = function () {
      var self = this

      // get the native debug to use it later
      var nativeDebug = global.debug && global.debug.bind(global.debug)
      // keep track of debug namespaces
      var debugNamespaces = {}

      global.debug = function (namespace) {
        // get the native debug method, namespaced
        debugNamespaces[namespace] = nativeDebug && nativeDebug.call(nativeDebug, namespace)

        // just return a function that will save all the args
        // and keeping track of the namespace
        return function () {
          try {
            var logLine = namespace + ' ' + self.createLogLine(arguments)
            self.saveLog({
              // also add the namespace to the line to make it more visible
              logLine: logLine,
              level: self.getLogLevel(namespace, logLine),
              // the name of the app in logdna
              app: self.debugAppName,
              metaData: {
                namespace: namespace
              }
            })
          } finally {
            // call the native debug
            debugNamespaces[namespace] && debugNamespaces[namespace].apply(debugNamespaces[namespace], arguments)
          }
        }
      }
    }

    /**
     * Enable the sending the debugs to logdna
     * this is called only on the recording page
     */
    Logger.enableDebugModule = function () {
      this._enabled = true
    }

    Logger.createLogLine = function (args) {
      // make it an array
      args = Array.prototype.slice.call(args)

      var string = ''
      try {
        args.forEach(function (arg) {
          if (typeof arg === 'object') {
            // if it's type ErrorEvent it will have a message attribute
            if (arg.message) {
              string += arg.message
              // if it's a blob
            } else if (arg instanceof Blob) {
              string += 'Blob, size ' + arg.size
            } else if (ArrayBuffer.isView(arg)) {
              string += arg.constructor.name + ' ' + arg.length
            } else {
              // else, just stringify it
              string += JSON.stringify(arg)
            }
          } else {
            // if string, array, etc
            string += arg.toString()
          }

          // add a sepparator between args
          string += ' '
        })

        return string
      } catch (e) {
        // in case we have any problem parsing the args
        // just glue them together and return
        return Array.prototype.join.call(args, ' ')
      }
    }

    Logger.createMetadataObject = function (object) {
      var app = global.app
      var metaData = Object.assign({}, object)

      if (app && app.user) {
        metaData['userId'] = app.user.id
        metaData['userName'] = app.user.get('displayName')
      }

      if (app && app.project) {
        metaData['projetName'] = app.project.get('name')
        metaData['projectId'] = app.project.id

        if (app.project.recorder && app.project.recorder.recording) {
          metaData['recordingId'] = app.project.recorder.recording.id
        }
      }

      if (global.servars && global.servars.pipelineVersion) {
        metaData['pipelineVersion'] = global.servars.pipelineVersion
      }

      return metaData
    }

    /**
     * Used to add metadata to a log line and add to queue
     * @param  {Object} options
     */
    Logger.saveLog = function (options) {
      var logLine = options.logLine
      var level = options.level || this.defaultLogLevel
      var logDnaApp = options.app || this.defaultApp

      if (!logLine || !level) return

      /**
       * Metadata for this log line
       * add any metadata that might come from the caller
       * Object.assign works even if options.metaData is undefined
       * @type {Object}
       */
      var metaData = Object.assign(this.metaData, options.metaData)
      metaData = this.createMetadataObject(metaData)

      this._logLines.push({
        // TODO: this does not take in account if the user's clock is off
        'timestamp': new Date().getTime(),
        'line': logLine,
        'app': logDnaApp,
        'level': level,
        'meta': metaData
      })

      // if we have enabled seding the logs
      // this should only be true on the recording page
      if (this._enabled) {
        this.sendLogToLogDNA()
      }
    }

    /**
     * Batch calls to logDNA
     * @param  {Bool} force If we want to force the send
     */
    Logger.sendLogToLogDNA = function (force) {
      var self = this
      global.clearTimeout(this._logTimeout)

      // batch calls to logdna
      if (this._logLines.length < this.batchSize && force !== true) {
        this._logTimeout = setTimeout(function () {
          self.sendLogToLogDNA(true)
        }, this.queueTimeout)
        return
      }

      /**
       * load the logs in a temporary array
       * @type {Array}
       */
      var buffer = this._logLines.slice(0)
      // clear this one in order to be used as the request gets made
      // if it fails the buffer lines will be added at the beginning to be sent again
      this._logLines = []

      /**
       * The object that will be sent to logdna
       * @type {Object}
       */
      var opts = {
        'lines': buffer
      }

      global.fetch(logDnaUrl, {
        method: 'post',
        body: JSON.stringify(opts),
        headers: {
          'accept': 'application/json',
          'content-type': 'application/json'
        }
      })
      .then(function (response) {
        return response.json()
      })
      .then(function (data) {
        if (data.status === 'ok') {
          // reset the array
          buffer = []
        } else {
          // something went wrong

          // add the lines at the beggining to be sent again
          self._logLines = buffer.concat(self._logLines)
          buffer = []

          self.surfaceError(data)
        }
      })
      .catch(function (data) {
        self.surfaceError(data)
      })
    }

    /**
     * Used to surface an error to the main thread so it will be logged and captured there
     * @param  {Object | String} error
     */
    Logger.surfaceError = function (error) {
      // if we are in a worker enviroment
      if (!global.window && global.postMessage) {
        global.postMessage({
          command: 'error',
          error: error
        })
      }
    }

    return Logger
  })()

  // make sure we have the vars available
  // it should only activate on the recording page (check for app.project)
  if (global.servars && global.servars.logging) {
    Logger.initialize(global.servars.logging)
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = Logger
  else global.Logger = Logger
})()

/* globals debug utils events ResumableUpload fetch */

var global = this;

(function () {
  'use strict'

  var dbg = debug('zc:cloudStore')

  var MIN_CHUNK_SIZE = 1024 * 256 // 256kb min size for cloud uploads

  var CloudStore = (function () {
    var CloudStore = function (config) {
      this.name = config.name || this.constructor.name
      this.format = config.format

      this.bytesUploaded = 0
      // if the file if finalized in GCS
      // this is a helpful state because it can get out of sync with the track's finalized state
      this.finalized = false
      this.uploadUrl = config.uploadUrl
      this.downloadUrl = null
      this.chunkSize = config.chunkSize || MIN_CHUNK_SIZE // 256kb

      this.origin = global.location.origin
      this.mimeType = config.mimeType
    }

    CloudStore.prototype.getMimeType = function () {
      var format = this.format
      // Soundboard samples will give their own mimeType
      //    as they do not have the same restrictions as tracks
      // If the mimeType for this store is set, do not introspect a new one
      if (this.mimeType) {
        return this.mimeType
      }
      if (format === 'wav') {
        return 'audio/wav'
      } else if (format === 'mp3') {
        return 'audio/mpeg'
      } else if (format === 'mov') {
        return 'video/quicktime'
      } else if (format === 'webm') {
        return 'video/webm'
      } else {
        throw new Error('Unable to retrieve mime type.  Missing format information.')
      }
    }

    /**
     *
     * @param isSoundboard {boolean} Indicates if the upload url is for a soundboard or not, this changes the bucket files get uploaded to.
     */
    CloudStore.prototype.createUploadUrl = function (projectId, path, isSoundboard) {
      var self = this
      dbg('Creating upload url.')
      var mimeType = this.getMimeType()
      var action = 'write'
      var url = '/api/cloud-storage/upload-url'
      return fetch(this.origin + url, {
        method: 'post',
        referrerPolicy: 'origin',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Origin': utils.getWindowOrigin(),
          'Referer': self.origin
        },
        body: JSON.stringify({
          mimeType: mimeType,
          projectId: projectId,
          path: path,
          action: action,
          isSoundboard: isSoundboard
        })
      }).then(function (res) {
        if (res.ok) return res.text()
        throw new Error('Create upload url request failed: ' + res.status)
      }).then(function (url) {
        self.uploadUrl = url
        return url
      }).catch(utils.logRethrow)
    }

    CloudStore.prototype.createDownloadUrl = function (projectId, path, isSoundboard) {
      var self = this
      dbg('Creating download url.')
      return fetch(this.origin + '/api/cloud-storage/download-url', {
        method: 'post',
        referrerPolicy: 'origin',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Origin': utils.getWindowOrigin(),
          'Referer': self.origin
        },
        body: JSON.stringify({
          projectId: projectId,
          path: path,
          isSoundboard: isSoundboard
        })
      }).then(function (res) {
        if (res.ok) return res.text()
        throw new Error('Create download url request failed: ' + res.status)
      }).then(function (url) {
        self.downloadUrl = url
        return url
      }).catch(utils.logRethrow)
    }

    CloudStore.prototype.delete = function (projectId, path, isSoundboard) {
      return fetch(this.origin + '/api/cloud-storage/delete', {
        method: 'post',
        referrerPolicy: 'origin',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Origin': utils.getWindowOrigin(),
          'Referer': self.origin
        },
        body: JSON.stringify({
          projectId: projectId,
          path: path,
          isSoundboard: isSoundboard
        })
      }).then(function (res) {
        if (res.ok) return res.text()
        throw new Error('Delete request failed: ' + res.status)
      }).then(function (url) {
        self.downloadUrl = url
        return url
      }).catch(utils.logRethrow)
    }

    /**
     * Used to get progress for an upload
     * TODO: change this so it uses the method inside upload.ts
     * @return {Promise}
     */
    CloudStore.prototype.getResumableUploadProgress = function () {
      var self = this

      var uploader = new ResumableUpload({
        url: this.uploadUrl,
        contentType: this.getMimeType()
      })

      return uploader.getUploadProgress().then(function (result) {
        self.bytesUploaded = result.bytesUploaded

        // if we get a 200 then the file is finalized and nothing can be added
        if (result.status === 200) {
          self.finalized = true
        }

        return result.bytesUploaded
      })
    }

    /**
     * Used to upload a blob to a GCS destination
     * It can start from 0 or from an offset
     * @param  {Object} options Structure: url, blob, [offset]
     * @return {Promise}
     */
    CloudStore.prototype.upload = function (options) {
      var self = this

      var uploader = new ResumableUpload({
        url: options.url,
        contentType: this.getMimeType(),
        progressCb: function (uploaded) {
          self.bytesUploaded = uploaded
          self.trigger('change:bytesUploaded', uploaded)
        }
      })

      // if we have an offset received, then we want this upload to start at that point
      if (options.offset) {
        this.bytesUploaded = options.offset
        uploader.bytesUploaded = options.offset
      }

      // we have only one chunk, so mark it as last
      return uploader.uploadChunk(options.blob, true).then(function () {
        self.trigger('chunkUploaded', {
          isLastChunk: true,
          uploadedBytes: uploader.bytesUploaded
        })
        self.finalized = true
      })
    }

    events.mixin(CloudStore.prototype)

    return CloudStore
  })()

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = CloudStore
  else global.CloudStore = CloudStore
})()
